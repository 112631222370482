// generate me a react hook for user persistence
// and authentication
import { useState, useEffect } from "react"
import { getAuth } from "firebase/auth"
import { initializeApp } from "firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyALHKn98c6se6HjuAG08I4RWhf3c8e7ID4",
  authDomain: "foodie-7b69b.firebaseapp.com",
  projectId: "foodie-7b69b",
  storageBucket: "foodie-7b69b.appspot.com",
  messagingSenderId: "117584697148",
  appId: "1:117584697148:web:afb4d4470f2ec0aae5f039",
  measurementId: "G-KEYCFMGHZ7"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth()

const useAuth = () => {
  const [user, setUser] = useState(null)

  const isAuthenticated = () => !!user

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user)
    })

    return () => unsubscribe()
  }, [])

  return user
}

export default useAuth
