import { useState, useEffect } from "react"
import COLOR_THEMES from "../helpers/color_themes"

const useColorTheme = () => {
  const [colorTheme, setColorTheme] = useState("light")

  useEffect(() => {
    const savedTheme = localStorage.getItem("colorTheme")
    if (savedTheme) {
      setColorTheme(savedTheme)
    }
  }, [])

  useEffect(() => {
    document.documentElement.style.backgroundColor =
      COLOR_THEMES[colorTheme].background
    document.body.style.backgroundColor = COLOR_THEMES[colorTheme].background
    document.body.style.color = COLOR_THEMES[colorTheme].inputText
    document.querySelector("meta[name='theme-color']").content =
      COLOR_THEMES[colorTheme].background
  }, [colorTheme])

  const toggleColorTheme = () => {
    document.documentElement.style.display = "none"
    const newTheme = colorTheme === "light" ? "dark" : "light"
    setColorTheme(newTheme)
    localStorage.setItem("colorTheme", newTheme)
    window.location.reload()
  }

  return { colorTheme, toggleColorTheme }
}

export default useColorTheme
