import React, { useState } from "react"
import COLOR_THEMES from "../helpers/color_themes"

const themeColors = COLOR_THEMES[localStorage.getItem("colorTheme") || "light"]

const SignInPage = ({ onSignIn }) => {
  const [loading, setLoading] = useState(true)

  React.useEffect(() => {
    // TODO: listen to the auth state
    setTimeout(() => {
      setLoading(false)
    }, 1200)
  }, [])

  if (loading)
    return (
      <div style={styles.container}>
        <img src="/logo192.png" height={80} style={styles.logoLoader} />
      </div>
    )

  return (
    <>
      <div style={styles.container}>
        <div style={styles.slidingBackground} />
        <div style={styles.modal}>
          <div style={styles.logo}>nyam</div>
          <div style={styles.signInButton} onClick={onSignIn}>
            Sign in with Google
          </div>
        </div>
      </div>
    </>
  )
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    position: "fixed",
    overflow: "hidden"
  },

  modal: {
    width: "300px",
    padding: "30px",
    backgroundColor: themeColors.signInModalBackground,
    borderRadius: "5px",
    textAlign: "center",
    marginTop: "-100px"
  },

  slidingBackground: {
    width: "100%",
    height: "100vh",
    backgroundColor: themeColors.background,
    backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="122" height="180"><text x="0" y="1em" font-size="25">🍎</text><text x="40" y="1em" font-size="25">🥯</text><text x="80" y="1em" font-size="25">🥬</text><text x="0" y="2.75em" font-size="25">🍕</text><text x="40" y="2.75em" font-size="25">🍔</text><text x="80" y="2.75em" font-size="25">🍦</text><text x="0" y="4.5em" font-size="25">🍎</text><text x="40" y="4.5em" font-size="25">🥯</text><text x="80" y="4.5em" font-size="25">🥬</text><text x="0" y="6.25em" font-size="25">🍕</text><text x="40" y="6.25em" font-size="25">🍔</text><text x="80" y="6.25em" font-size="25">🍦</text></svg>')`,
    backgroundSize: "122px 180px",
    animation: "slide 60s linear infinite",
    filter: "blur(2px)",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
    opacity: 0.25,
    overflow: "hidden"
  },

  logo: {
    fontSize: "xxx-large",
    fontWeight: 500,
    marginBottom: "10px",
    fontFamily: "Ranga",
    color: themeColors.signInModalColor
  },

  signInButton: {
    backgroundColor: "#4285f4",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer"
  },

  spinner: {
    border: "5px solid rgba(0,0,0,0.1)",
    borderTop: "5px solid #4285f4",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    animation: "spin 1s linear infinite"
  },

  logoLoader: {
    animation: "pulse 2s linear infinite",
    marginTop: "-100px"
  }
}

export default SignInPage
