import React from "react"
import COLOR_THEMES from "../helpers/color_themes"
import useWindowDimensions from "../hooks/useWindowDimensions"
import useColorTheme from "../hooks/useColorTheme"

const themeColors = COLOR_THEMES[localStorage.getItem("colorTheme") || "light"]

const Entry = ({
  description,
  nutritionalInfo,
  id,
  ingredients,
  onRemove,
  highlighted
}) => {
  const windowWidth = useWindowDimensions().width
  const MEDIUM_WINDOW_WIDTH_BREAKPOINT = 1000
  const SMALL_WINDOW_WIDTH_BREAKPOINT = 600

  const styles =
    windowWidth > MEDIUM_WINDOW_WIDTH_BREAKPOINT
      ? regularStyles
      : windowWidth > SMALL_WINDOW_WIDTH_BREAKPOINT
      ? smallStyles
      : extraSmallStyles

  const [expanded, setExpanded] = React.useState(false)
  const { calories, protein, fat, carbohydrates } = nutritionalInfo

  const toggleExpanded = () => {
    if (ingredients.length <= 1) return
    setExpanded(!expanded)
  }

  return (
    <div
      style={{
        ...styles.tableItemWrapper,
        backgroundColor: expanded
          ? themeColors.expandedTableItemWrapperBackground
          : themeColors.tableItemWrapperBackground,
        border: highlighted ? "1px solid #777" : "none"
      }}
      onClick={toggleExpanded}
    >
      <div style={styles.tableItem}>
        <>
          <div style={styles.entryLeftSide}>{description}</div>
          <div style={styles.entryRightSide}>
            <div style={styles.totalAmounts}>
              <div style={styles.totalAmount}>
                <div style={styles.metricIcon}>🔥</div>
                <div style={styles.totalAmountNumber}>
                  <span style={{ fontWeight: "bold" }}>{calories}</span> kcal
                </div>
              </div>
              <div style={styles.totalAmount}>
                <div style={styles.metricIcon}>💪</div>
                <div style={styles.totalAmountNumber}>
                  <span style={{ fontWeight: "bold" }}>{protein}g</span> protein
                </div>
              </div>
              <div style={styles.totalAmount}>
                <div style={styles.metricIcon}>🍚</div>
                <div style={styles.totalAmountNumber}>
                  <span style={{ fontWeight: "bold" }}>{carbohydrates}g</span>{" "}
                  carbs
                </div>
              </div>
              <div style={styles.totalAmount}>
                <div style={styles.metricIcon}>🧈</div>
                <div style={styles.totalAmountNumber}>
                  <span style={{ fontWeight: "bold" }}>{fat}g</span> fat
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      {expanded && ingredients.length > 1 && (
        <div style={styles.expandedIngredients}>
          {ingredients.map(
            ({ description, calories, protein, fat, carbohydrates }) => (
              <div style={styles.expandedTableItem}>
                <div
                  style={{
                    ...styles.entryLeftSide,
                    fontSize: "16px",
                    opacity: "0.9"
                  }}
                >
                  {description}
                </div>
                <div
                  style={{
                    ...styles.entryRightSide,
                    filter: "grayscale(1)"
                  }}
                >
                  <div style={styles.totalAmounts}>
                    <div style={styles.totalAmount}>
                      <div style={{ ...styles.metricIcon }}>🔥</div>
                      <div style={styles.totalAmountNumber}>
                        <span style={{ fontWeight: "bold" }}>{calories}</span>{" "}
                        kcal
                      </div>
                    </div>
                    <div style={styles.totalAmount}>
                      <div style={{ ...styles.metricIcon }}>💪</div>
                      <div style={styles.totalAmountNumber}>
                        <span style={{ fontWeight: "bold" }}>{protein}g</span>{" "}
                        protein
                      </div>
                    </div>
                    <div style={styles.totalAmount}>
                      <div style={{ ...styles.metricIcon }}>🍚</div>
                      <div style={styles.totalAmountNumber}>
                        <span style={{ fontWeight: "bold" }}>
                          {carbohydrates}g
                        </span>{" "}
                        carbs
                      </div>
                    </div>
                    <div style={styles.totalAmount}>
                      <div style={{ ...styles.metricIcon }}>🧈</div>
                      <div style={styles.totalAmountNumber}>
                        <span style={{ fontWeight: "bold" }}>{fat}g</span> fat
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      )}
      {ingredients.length > 1 ? (
        <div style={styles.showMoreButton}>{expanded ? "▲" : "▼"}</div>
      ) : null}
      <div style={styles.removeButton} onClick={() => onRemove(id)}>
        ×
      </div>
    </div>
  )
}
const regularStyles = {
  tableItem: {
    display: "flex",
    padding: "30px",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "30px"
  },
  expandedTableItem: {
    display: "flex",
    padding: "30px",
    paddingBottom: "30px",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "30px",
    paddingTop: "0",
    alignItems: "center"
  },
  tableItemWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "20px",
    paddingTop: "20px",
    paddingLeft: "30px",
    paddingRight: "30px",
    position: "relative",
    background: themeColors.tableItemWrapperBackground,
    marginBottom: "10px",
    animation: "fadeBorder 6s forwards",
    animationDelay: "2s",
    borderRadius: "12px"
  },
  showMoreButton: {
    textAlign: "center",
    color: themeColors.showMoreButtonColor,
    fontSize: "8px",
    cursor: "pointer"
  },
  metric: {
    fontSize: "14px",
    color: themeColors.metricColor
  },
  removeButton: {
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: themeColors.removeButtonBackground,
    lineHeight: "30px",
    color: themeColors.removeButtonColor,
    position: "absolute",
    top: 0,
    right: 0,
    margin: "30px",
    cursor: "pointer"
  },
  entryLeftSide: {
    minWidth: "200px",
    maxWidth: "500px",
    fontSize: "18px",
    fontWeight: "500",
    flexShrink: 1
  },
  entryRightSide: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: "200px",
    gap: "10px",
    flexShrink: 0
  },
  expandedIngredients: {
    opacity: 0.8
  },
  totalAmounts: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: "8px",
    opacity: "0.75",
    flexWrap: "nowrap",
    gap: "10px",
    width: "100%"
  },
  totalAmount: {
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    width: "80px"
  },
  totalAmountNumber: {
    display: "flex",
    flexDirection: "column"
  },
  metricIcon: {}
}

const smallStyles = {
  ...regularStyles,
  tableItem: {
    ...regularStyles.tableItem,
    flexDirection: "column"
  },
  expandedTableItem: {
    ...regularStyles.expandedTableItem,
    flexDirection: "column",
    paddingBottom: "30px",
    paddingTop: 0,
    alignItems: "normal"
  }
}

const extraSmallStyles = {
  ...smallStyles,
  tableItem: {
    ...smallStyles.tableItem,
    padding: "10px",
    paddingBottom: "30px"
  },
  expandedTableItem: {
    ...smallStyles.expandedTableItem,
    padding: "0 10px",
    paddingBottom: "30px"
  },
  entryLeftSide: {
    ...smallStyles.entryLeftSide,
    fontSize: "16px"
  },
  totalAmount: {
    ...smallStyles.totalAmount,
    flexDirection: "column",
    gap: "5px",
    textAlign: "center",
    width: "auto"
  }
}
export default Entry
