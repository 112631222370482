class ExtractNutritionalInfoError extends Error {
  constructor() {
    super(
      "Could not extract nutritional info from the text. Make sure you provide a description containing food items and quantities."
    )
    this.name = "ExtractNutritionalInfoError"
  }
}

export default ExtractNutritionalInfoError
