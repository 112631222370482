const COLOR_THEMES = {
  light: {
    signInModalBackground: "#fff",
    signInModalColor: "#000",
    createEntryButtonColor: "#000",
    background: "#f8f8f8", // Background color for containers
    separatorLine: "#d8d8d8", // Color for separator lines
    dateWrapperBackground: "#f8f8f8", // Background color for date wrapper
    inputBackground: "#ffffff", // Background color for input fields
    inputText: "#000000", // Text color for input fields
    errorMessageText: "#606060", // Text color for error messages
    logoText: "#aaa", // Text color for logo
    tableItemWrapperBackground: "#f1f1f1",
    expandedTableItemWrapperBackground: "#ebebeb",
    removeButtonBackground: "#f2f2f2",
    removeButtonColor: "#898989",
    metricColor: "#666",
    showMoreButtonColor: "#898989"
  },
  dark: {
    signInModalBackground: "#252525",
    signInModalColor: "#fff",
    createEntryButtonColor: "#fff",
    background: "#181818", // Background color for containers
    separatorLine: "#515151", // Color for separator lines
    dateWrapperBackground: "#181818", // Background color for date wrapper
    inputBackground: "#323232", // Background color for input fields
    inputText: "#ffffff", // Text color for input fields
    errorMessageText: "#606060", // Text color for error messages
    logoText: "#aaa", // Text color for logo
    tableItemWrapperBackground: "#252525",
    expandedTableItemWrapperBackground: "#323232",
    removeButtonBackground: "#323232",
    removeButtonColor: "#ffffff77",
    metricColor: "#666",
    showMoreButtonColor: "#898989"
  }
}

export default COLOR_THEMES
