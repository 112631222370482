import React from "react"

const Calendar = () => {
  return (
    <div style={styles.calendarContainer}>
      <h1>Calendar</h1>
    </div>
  )
}

export default Calendar

const styles = {
  calendarContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#181818",
    position: "fixed",
    top: 0,
    left: 0
  }
}
