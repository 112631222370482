import React from "react"
import Entry from "./components/Entry"
import Calendar from "./components/Calendar"
import useWindowDimensions from "./hooks/useWindowDimensions"
import { initializeApp } from "firebase/app"
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth"
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
  Timestamp,
  deleteDoc
} from "firebase/firestore"
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator
} from "firebase/functions"

import useAuth from "./hooks/useAuth"
import SignInPage from "./pages/SignInPage"
import ExtractNutritionalInfoError from "./errors/ExtractNutritionalInfoError"
import COLOR_THEMES from "./helpers/color_themes"
import useColorTheme from "./hooks/useColorTheme"

// *** Implementation ** //
const firebaseConfig = {
  apiKey: "AIzaSyALHKn98c6se6HjuAG08I4RWhf3c8e7ID4",
  authDomain: "foodie-7b69b.firebaseapp.com",
  projectId: "foodie-7b69b",
  storageBucket: "foodie-7b69b.appspot.com",
  messagingSenderId: "117584697148",
  appId: "1:117584697148:web:afb4d4470f2ec0aae5f039",
  measurementId: "G-KEYCFMGHZ7"
}

// const app = initializeApp(firebaseConfig)

const themeColors = COLOR_THEMES[localStorage.getItem("colorTheme") || "light"]

const ERROR_DISPLAY_TIME = 4000

const fetchNutritionalInfo = async (mealDescription) => {
  const firebaseApp = initializeApp(firebaseConfig)
  const functions = getFunctions(firebaseApp, "europe-west1")

  // connectFunctionsEmulator(functions, "127.0.0.1", 5001)
  const getNutritionalInfo = httpsCallable(functions, "getNutritionalInfo")

  const response = await getNutritionalInfo({ mealDescription })
  if (response.data.error) {
    throw new ExtractNutritionalInfoError()
  }

  return response.data
}

const MainScreen = () => {
  const windowWidth = useWindowDimensions().width
  const user = useAuth()

  const provider = new GoogleAuthProvider()

  const MEDIUM_WINDOW_WIDTH_BREAKPOINT = 1000
  const SMALL_WINDOW_WIDTH_BREAKPOINT = 600

  const { colorTheme, toggleColorTheme } = useColorTheme()

  const styles =
    windowWidth > MEDIUM_WINDOW_WIDTH_BREAKPOINT
      ? regularStyles
      : windowWidth > SMALL_WINDOW_WIDTH_BREAKPOINT
      ? smallStyles
      : extraSmallStyles

  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false)

  const [value, setValue] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [entries, setEntries] = React.useState([])
  const [highlightedEntry, setHighlightedEntry] = React.useState(null)
  const [showUserDropdown, setShowUserDropdown] = React.useState(false)
  const [currentErrorMessage, setCurrentErrorMessage] = React.useState(null)

  const getData = async () => {
    const firebaseApp = initializeApp(firebaseConfig)
    const db = getFirestore(firebaseApp)

    const mealsRef = collection(db, "meals")
    const q = query(mealsRef, where("userId", "==", user.uid))

    const querySnapshot = await getDocs(q)
    setEntries(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [entries])

  React.useEffect(() => {
    if (!user) return
    getData()
  }, [user])

  const handleGoogleSignIn = () => {
    const auth = getAuth()
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result)
        const token = credential.accessToken
        const user = result.user
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        const email = error.customData.email
        const credential = GoogleAuthProvider.credentialFromError(error)
      })
  }

  const createEntry = async (value) => {
    if (!value) {
      return
    }

    setLoading(true)

    try {
      const response = await fetchNutritionalInfo(value)
      const ingredients = Array.isArray(response[Object.keys(response)[0]])
        ? response[Object.keys(response)[0]]
        : [response]

      const nutritionalInfo = ingredients.reduce((acc, ingredient) => {
        return {
          calories: acc.calories + ingredient.calories,
          protein: acc.protein + ingredient.protein,
          carbohydrates: acc.carbohydrates + ingredient.carbohydrates,
          fat: acc.fat + ingredient.fat
        }
      })

      const formattedEntry = {
        ...nutritionalInfo,
        description: value,
        ingredients,
        date: Timestamp.now()
      }

      // save to firestore
      const firebaseApp = initializeApp(firebaseConfig)
      const db = getFirestore(firebaseApp)

      const mealsRef = collection(db, "meals")

      const docRef = await addDoc(mealsRef, {
        ...formattedEntry,
        userId: user.uid
      })

      getData()
      setHighlightedEntry(docRef.id)
    } catch (error) {
      displayError(error)
    }

    setValue("")
    setLoading(false)
  }

  const displayError = (error) => {
    if (error.name !== "ExtractNutritionalInfoError") return
    setCurrentErrorMessage(error.message)
    setTimeout(() => {
      setCurrentErrorMessage(null)
    }, ERROR_DISPLAY_TIME)
  }

  const removeEntry = async (id) => {
    const firebaseApp = initializeApp(firebaseConfig)
    const db = getFirestore(firebaseApp)
    const mealsRef = collection(db, "meals")

    await deleteDoc(doc(mealsRef, id))
    getData()
  }

  const getEntriesByDay = () => {
    const toLocalISOString = (date) => {
      const offset = date.getTimezoneOffset() * 60000
      const localISOTime = new Date(date - offset).toISOString().slice(0, 10)
      return localISOTime
    }

    const entriesByDay = entries.reduce((acc, entry) => {
      const date = toLocalISOString(entry.date.toDate()).split("T")[0]
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(entry)
      return acc
    }, {})

    return entriesByDay
  }

  const renderEntries = (entries) => {
    return entries.map((entry) => {
      const {
        description,
        calories,
        protein,
        carbohydrates,
        fat,
        id,
        ingredients
      } = entry
      const nutritionalInfo = { calories, protein, carbohydrates, fat }
      return (
        <Entry
          key={id}
          {...{ description, nutritionalInfo, id, ingredients }}
          onRemove={removeEntry}
          highlighted={highlightedEntry === id}
        />
      )
    })
  }

  const entriesByDay = getEntriesByDay()
  const sortedKeys = Object.keys(entriesByDay).sort().reverse()
  const sortedEntriesByDay = sortedKeys.map((key) => ({
    date: key,
    entries: entriesByDay[key].sort((a, b) => b.date - a.date)
  }))
  const totalsByDay = sortedEntriesByDay.map(({ entries }) => {
    return entries.reduce(
      (acc, entry) => {
        const { calories, protein, carbohydrates, fat } = entry
        return {
          calories: acc.calories + calories,
          protein: acc.protein + protein,
          carbohydrates: acc.carbohydrates + carbohydrates,
          fat: acc.fat + fat
        }
      },
      { calories: 0, protein: 0, carbohydrates: 0, fat: 0 }
    )
  })

  const randomEmojis = React.useMemo(() => {
    const foodEmojis = [
      "🍏",
      "🍎",
      "🍐",
      "🍊",
      "🍋",
      "🍌",
      "🍉",
      "🍇",
      "🍓",
      "🫐",
      "🍈",
      "🍒",
      "🍑",
      "🥭",
      "🍍",
      "🥥",
      "🥝",
      "🍅",
      "🍆",
      "🥑",
      "🥦",
      "🥬",
      "🥒",
      "🌶️",
      "🌽",
      "🥕",
      "🧄",
      "🧅",
      "🥔",
      "🍠",
      "🍯",
      "🍞",
      "🥐",
      "🥖",
      "🥨",
      "🧀",
      "🥚",
      "🍳",
      "🥞",
      "🧇",
      "🥓",
      "🥩",
      "🍗",
      "🍖",
      "🦴",
      "🌭",
      "🍔",
      "🍟",
      "🍕",
      "🥪",
      "🥙",
      "🧆",
      "🥘",
      "🍲",
      "🥣",
      "🥗",
      "🍿",
      "🧈",
      "🧂",
      "🥫"
    ]

    const randomEmojis = []
    while (randomEmojis.length < 3) {
      const randomIndex = Math.floor(Math.random() * foodEmojis.length)
      if (!randomEmojis.includes(foodEmojis[randomIndex])) {
        randomEmojis.push(foodEmojis[randomIndex])
      }
    }
    return randomEmojis
  }, [loading])

  const renderSpinner = () => {
    return randomEmojis.map((emoji, i) => (
      <div
        key={emoji}
        style={{
          fontSize: "20px",
          animation: "jump 2s infinite",
          animationTimingFunction: "cubic-bezier(0.6, -0.28, 0.735, 0.045)",
          animationDelay: `${i * 0.2}s`
        }}
      >
        {emoji}
      </div>
    ))
  }

  // *** Render *** //

  if (!user) {
    return <SignInPage onSignIn={handleGoogleSignIn} />
  }

  return (
    <>
      <div style={styles.banner}>
        <div style={styles.logo}>nyam</div>
        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <div style={{ cursor: "pointer" }} onClick={toggleColorTheme}>
            {colorTheme === "light" ? "🌙" : "☀️"}
          </div>
          {user ? (
            <div
              style={{ position: "relative" }}
              onClick={() => setShowUserDropdown(!showUserDropdown)}
              onBlur={() => setShowUserDropdown(false)}
              tabIndex={0}
            >
              <div
                style={{ width: "35px", height: "35px", borderRadius: "50%" }}
              >
                <img
                  src={user.photoURL}
                  alt="User profile"
                  style={{ width: "100%", borderRadius: "50%" }}
                />
              </div>
              {showUserDropdown ? (
                <div
                  style={styles.userDropdown}
                  onClick={() => {
                    setShowUserDropdown(false)
                    const auth = getAuth()
                    auth.signOut()
                  }}
                >
                  Sign out
                </div>
              ) : null}
            </div>
          ) : (
            <div onClick={handleGoogleSignIn} style={styles.signInButton}>
              Sign in
            </div>
          )}
        </div>
      </div>
      <div style={styles.container}>
        <div style={styles.tableContainer}>
          {sortedEntriesByDay.map(({ date, entries }, i) => {
            return (
              <>
                <div style={styles.dayContainer}>
                  <div style={styles.dateWrapper}>
                    <h3 style={styles.dateTitle}>
                      {new Date(date).toLocaleDateString("en-ES", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric"
                      })}
                    </h3>
                  </div>
                  {renderEntries(entries)}
                  <div style={styles.totalAmounts}>
                    <div style={styles.totalAmount}>
                      <div style={styles.metricIcon}>🔥</div>
                      <div style={styles.totalAmountNumber}>
                        <span style={{ fontWeight: "bold" }}>
                          {totalsByDay[i].calories}
                        </span>{" "}
                        kcal
                      </div>
                    </div>
                    <div style={styles.totalAmount}>
                      <div style={styles.metricIcon}>💪</div>
                      <div style={styles.totalAmountNumber}>
                        <span style={{ fontWeight: "bold" }}>
                          {totalsByDay[i].protein}g
                        </span>{" "}
                        protein
                      </div>
                    </div>
                    <div style={styles.totalAmount}>
                      <div style={styles.metricIcon}>🍚</div>
                      <div style={styles.totalAmountNumber}>
                        <span style={{ fontWeight: "bold" }}>
                          {totalsByDay[i].carbohydrates}g
                        </span>{" "}
                        carbs
                      </div>
                    </div>
                    <div style={styles.totalAmount}>
                      <div style={styles.metricIcon}>🧈</div>
                      <div style={styles.totalAmountNumber}>
                        <span style={{ fontWeight: "bold" }}>
                          {totalsByDay[i].fat}g
                        </span>{" "}
                        fat
                      </div>
                    </div>
                  </div>
                </div>
                {i < sortedEntriesByDay.length - 1 ? (
                  <div style={styles.daySeparator} />
                ) : null}
              </>
            )
          })}
        </div>
        <div style={styles.foodInputWrapper}>
          <div style={styles.tableContainer}>
            <div
              style={{
                ...styles.foodInputBox,
                animation: currentErrorMessage
                  ? "0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0s 1 normal both running shake"
                  : "none"
              }}
            >
              {!loading ? (
                !currentErrorMessage ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5px"
                    }}
                  >
                    <textarea
                      style={styles.input}
                      onChange={(e) => {
                        setValue(e.target.value)
                        e.target.style.height = "inherit"
                        e.target.style.height = `${e.target.scrollHeight}px`
                      }}
                      value={value}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") createEntry(value)
                      }}
                      placeholder="What did you eat today?"
                      rows="1"
                    />
                    {value.length ? (
                      <button
                        style={styles.createEntryButton}
                        onClick={() => createEntry(value)}
                      >
                        Add
                      </button>
                    ) : null}
                  </div>
                ) : (
                  <div
                    style={styles.errorMessage}
                    onClick={() => setCurrentErrorMessage(null)}
                  >
                    {currentErrorMessage}
                  </div>
                )
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    lineHeight: "19px"
                  }}
                >
                  {/* <div
                    style={{
                      whiteSpace: "nowrap",
                      maxWidth: "95%",
                      overflow: "hidden",
                      fontSize: "16px"
                    }}
                  >
                    {value}
                  </div> */}
                  {renderSpinner()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isCalendarOpen && <Calendar />}
    </>
  )
}

const regularStyles = {
  container: {
    display: "flex",
    fontSize: "14px",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingBottom: "150px",
    marginTop: "50px"
  },
  tableContainer: {
    width: "100%",
    padding: "40px",
    flex: 1
  },
  dateWrapper: {
    background: themeColors.dateWrapperBackground,
    position: "sticky",
    top: "50px",
    zIndex: 50,
    paddingBottom: "1em"
  },
  dateTitle: {
    paddingLeft: "60px",
    margin: 0
  },
  dayContainer: {
    paddingTop: "30px",
    paddingBottom: "30px"
  },
  daySeparator: {
    width: "150px",
    height: "0px",
    background: themeColors.separatorLine,
    margin: "0 auto"
  },
  tableItemWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: "30px",
    marginBottom: "20px",
    borderRadius: "8px",
    boxShadow: "0px 0px 80px -20px rgba(0, 0, 0, 0.2)",
    gap: "20px"
  },
  foodInputWrapper: {
    background: `linear-gradient(0deg, ${themeColors.dateWrapperBackground} 20%, ${themeColors.dateWrapperBackground}00 100%)`,
    position: "fixed",
    bottom: "0",
    zIndex: "100",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  foodInputBox: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: themeColors.inputBackground,
    marginBottom: "20px",
    borderRadius: "8px",
    boxShadow: "0px 0px 80px -20px rgba(0, 0, 0, 0.2)",
    gap: "20px",
    position: "sticky",
    width: "100%",
    padding: "40px"
  },
  input: {
    outlineStyle: "none",
    width: "100%",
    border: "none",
    fontSize: "16px",
    fontFamily: "inherit",
    padding: 0,
    resize: "none",
    backgroundColor: themeColors.inputBackground,
    color: themeColors.inputText,
    placeholderColor: "white"
  },
  createEntryButton: {
    cursor: "pointer",
    backgroundColor: "transparent",
    color: themeColors.createEntryButtonColor,
    borderRadius: "8px",
    border: "none",
    height: "fit-content",
    alignSelf: "flex-end",
    fontFamily: "inherit",
    fontWeight: "500",
    fontSize: "16px"
  },
  newDayButton: {
    borderTopColor: "black",
    borderTopWidth: "1px",
    padding: "10px",
    cursor: "pointer",
    textAlign: "center",
    opacity: "0.5",
    backgroundColor: "transparent"
  },
  totalAmounts: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingLeft: "60px",
    paddingRight: "60px",
    paddingTop: "30px",
    paddingBottom: "10px",
    borderRadius: "8px",
    opacity: "0.75",
    flexWrap: "nowrap",
    gap: "10px",
    width: "100%"
  },
  totalAmount: {
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    width: "80px"
  },
  totalAmountNumber: {
    display: "flex",
    flexDirection: "column"
  },
  metricIcon: {},
  banner: {
    position: "fixed",
    textAlign: "center",
    top: 0,
    width: "100%",
    padding: "1em 100px",
    backgroundColor: themeColors.dateWrapperBackground,
    zIndex: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "50px"
  },
  spinner: {
    border: "2px solid rgba(0, 0, 0, 0.1)",
    borderTop: "2px solid black",
    borderRadius: "50%",
    width: "16px",
    height: "16px",
    animation: "spin 1s linear infinite",
    alignSelf: "center"
  },
  signInButton: {
    cursor: "pointer",
    color: "black"
  },
  logo: {
    fontWeight: 500,
    color: themeColors.logoText,
    fontFamily: "Ranga",
    fontSize: "28px"
  },
  userDropdown: {
    position: "absolute",
    top: "50px",
    right: "0",
    backgroundColor: themeColors.inputBackground,
    padding: "10px",
    width: "100px",
    cursor: "pointer"
  },
  errorMessage: {
    fontSize: "16px",
    textAlign: "center",
    color: themeColors.errorMessageText
  }
}

const smallStyles = {
  ...regularStyles,
  container: {
    ...regularStyles.container
  },
  tableContainer: {
    ...regularStyles.tableContainer,
    padding: "10px"
  },
  dayContainer: {
    ...regularStyles.dayContainer
  },
  daySeparator: {
    ...regularStyles.daySeparator,
    height: "2px",
    margin: "10px auto"
  },
  foodInputBox: {
    ...regularStyles.foodInputBox,
    padding: "20px"
  },
  totalAmounts: {
    ...regularStyles.totalAmounts,
    paddingLeft: "60px",
    paddingRight: "60px",
    paddingTop: "15px",
    paddingBottom: "5px",
    justifyContent: "space-between",
    width: "auto"
  },
  totalAmount: {
    ...regularStyles.totalAmount,
    alignItems: "center",
    gap: "10px"
  },
  banner: {
    ...regularStyles.banner,
    padding: "1em 70px"
  }
}

const extraSmallStyles = {
  ...smallStyles,
  totalAmounts: {
    ...smallStyles.totalAmounts,
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  dateTitle: {
    ...smallStyles.dateTitle,
    paddingLeft: "40px"
  },
  totalAmount: {
    ...smallStyles.totalAmount,
    flexDirection: "column",
    gap: "5px",
    textAlign: "center"
  },
  banner: {
    ...smallStyles.banner,
    padding: "1em 50px"
  }
}

export default MainScreen
